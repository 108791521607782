<template>
  <div>
    <div>{$route.query.code}</div>
    <div>{$route.query.state}</div>
  </div>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';
import {loginService} from '@/services/login.service';
import {NaverLoginDto} from '@/services/LoginModels';

@Component
export default class NaverCallBack extends Vue {
  code = this.$route.query.code;
  state = this.$route.query.state;
  naverLoginDto = {
    code: this.code,
    state: this.state,
  };

  mounted() {
    console.log('test');
    console.log(this.code);
    console.log(this.state);

    loginService.naverLogin(this.naverLoginDto).then(response => {
      if (response.status == 200) {
        const responseData = response.data;
        if (responseData.resultCode == '0000') {
          //로그인 성공 mypage로 이동
          console.log(responseData);
          console.log('회원가입 성공 Home으로 이동');
        } else {
          //PMS 로그인 에러처리 필요
          console.log('회원가입 실패:' + responseData.msg);
        }
      } else {
        //http response 오류 처리
      }
    });
  }
}
</script>

<style scoped></style>
